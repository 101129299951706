import React from "react";
import {
  AppBar,
  Divider,
  Drawer,
  List,
  ListItem as MuiListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  Typography,
  IconButton,
  createStyles,
} from "@material-ui/core";
import { Theme, withStyles, WithStyles } from "@material-ui/core/styles";
import CloseIcon from "@material-ui/icons/Close";
import HomeIcon from "@material-ui/icons/Home";
import MenuIcon from "@material-ui/icons/Menu";
import DoneIcon from "@material-ui/icons/DoneOutline";
import ContactIcon from "@material-ui/icons/ContactMail";
import CallIcon from "@material-ui/icons/Call";
import DashboardIcon from "@material-ui/icons/Dashboard";
import EmailIcon from "@material-ui/icons/Email";
import GroupIcon from "@material-ui/icons/Group";
import ServiceIcon from "@material-ui/icons/ImportantDevices";
import PieChart from "@material-ui/icons/PieChart";
import { Image } from "../image/Image";
import { compose, withHandlers, withState } from "recompose";
import ListItem from "../navigation/ListItem";
import Text from "../general/typo/Text";
import { colors } from "../../theme/defaultTheme";

type NavigationBarProps = {
  logo: any;
  title: string;
  drawerOpen: boolean;
  toggleDrawer(): void;
};

type NavigationPublicProps = {
  title: string;
  // image with logo.fixed
  logo: any;
};

const withToggle = compose<NavigationBarProps, NavigationPublicProps>(
  withState("drawerOpen", "toggle", false),
  withHandlers({
    showDrawer: ({ toggle }: any) => () => toggle(true),
    hideDrawer: ({ toggle }: any) => () => toggle(false),
    toggleDrawer: ({ toggle }: any) => () =>
      toggle((current: boolean) => !current),
  })
);

const NavigationBar: React.FC<NavigationBarProps &
  WithStyles<typeof styles>> = ({
  classes,
  title,
  drawerOpen,
  toggleDrawer,
  logo,
}) => {
  return (
    <Grid container spacing={8} className={classes.root}>
      <AppBar color="default" position="fixed" className={classes.appBar}>
        <Image
          image={{
            ...logo,
            alt: "Twteam Logo",
            description: "Logo of Twteam",
            fadeIn: false,
          }}
          className={classes.logo}
        />

        <Typography variant="body1" className={classes.logoText}>
          {title}
        </Typography>

        <IconButton
          onClick={toggleDrawer}
          className={classes.menuButton}
          color="inherit"
          aria-label="Menu"
        >
          <MenuIcon />
        </IconButton>
      </AppBar>

      <Drawer
        anchor={"right"}
        transitionDuration={700}
        open={drawerOpen}
        onClose={toggleDrawer}
        variant={"temporary"}
      >
        <AppBar color="default" position="sticky" className={classes.appBar}>
          <Image
            image={{
              ...logo,
              fadeIn: false,
              alt: "Twteam Logo",
              description: "Logo of Twteam",
            }}
            className={classes.logo}
          />

          <Text className={classes.logoText}>{title}</Text>

          <IconButton
            onClick={toggleDrawer}
            className={classes.menuButton}
            color="inherit"
            aria-label="Menu"
          >
            <CloseIcon />
          </IconButton>
        </AppBar>

        <List component="nav">
          <ListItem
            onClick={toggleDrawer}
            button
            to={"/"}
            className={
              window.location.pathname === "/" ? classes.activeItem : ""
            }
          >
            <ListItemIcon>
              <HomeIcon />
            </ListItemIcon>
            <ListItemText primary="Home" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            button
            to={"/services"}
            className={
              window.location.pathname === "/services" ? classes.activeItem : ""
            }
          >
            <ListItemIcon>
              <ServiceIcon />
            </ListItemIcon>
            <ListItemText primary="Services" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            button
            to={"/projekte"}
            className={
              window.location.pathname === "/projekte" ? classes.activeItem : ""
            }
          >
            <ListItemIcon>
              <DoneIcon />
            </ListItemIcon>
            <ListItemText primary="Projekte" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            button
            to={"/team"}
            className={
              window.location.pathname === "/team" ? classes.activeItem : ""
            }
          >
            <ListItemIcon>
              <GroupIcon />
            </ListItemIcon>
            <ListItemText primary="Team" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            button
            to={"/dashboard"}
            className={
              window.location.pathname === "/dashboard"
                ? classes.activeItem
                : ""
            }
          >
            <ListItemIcon>
              <PieChart />
            </ListItemIcon>
            <ListItemText primary="Dashboard" />
          </ListItem>

          <ListItem
            onClick={toggleDrawer}
            button
            to={"/kontakt"}
            className={
              window.location.pathname === "/kontakt" ? classes.activeItem : ""
            }
          >
            <ListItemIcon>
              <ContactIcon />
            </ListItemIcon>
            <ListItemText primary="Kontakt" />
          </ListItem>
        </List>

        <ListItem
          onClick={toggleDrawer}
          button
          to={"/blog"}
          className={
            window.location.pathname === "/blog" ? classes.activeItem : ""
          }
        >
          <ListItemIcon>
            <DashboardIcon />
          </ListItemIcon>
          <ListItemText primary="Blog" />
        </ListItem>

        <Divider color={colors.gold} />
        <List component="nav">
          <MuiListItem button component={"a"} href={"mailto:info@twteam.ch"}>
            <ListItemIcon>
              <EmailIcon />
            </ListItemIcon>
            <ListItemText primary="Direkt schreiben" />
          </MuiListItem>
        </List>
      </Drawer>
    </Grid>
  );
};

const styles = (theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
      boxShadow: "none",
    },
    appBarOpen: {
      boxShadow: "none",
    },
    activeItem: {
      backgroundColor: "#BF9B30",
    },
    logo: {
      width: "90px",
      margin: "9px",
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      justifyContent: "space-between",
    },
    logoText: {
      ...theme.overrides!.MuiAppBar!.colorDefault,
      textTransform: "uppercase",
      fontWeight: "bold",
    },
    menuButton: {
      float: "right",
    },
  });

export default compose<
  NavigationBarProps & WithStyles<typeof styles>,
  NavigationPublicProps
>(
  withStyles(styles),
  withToggle
)(NavigationBar);
